nav {
  background-color: white;
  padding: 2.5rem 1.8vmax;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  transition: all 0.3s ease;
  position: relative;
}
.nav2 {
  padding: 0vmax 3vmax !important;
}

/* ------------ nav part1-------------- */
.nav_part1 {
  height: 100%;
  /* width: 15%; */
  /* background-color: red; */
}
.nav_cat_btn {
  display: flex;
  align-items: center;
  padding: 6px 15px;
  border: 2px solid #4579f9;
  color: #4579f9;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
}
.nav_cat_btn:hover {
  background-color: #4579f9;
  color: white;
  transition: all 0.3s ease;
}
.drop {
  height: 300px;
  width: 200px;
  /* background-color: rgb(255, 1, 1); */
  position: absolute;
  top: 100%;
  left: 0%;
  z-index: 9999 !important;
}

/* ------------ nav part2-------------- */
.nav_part2 {
  height: 100%;
  /* width: 55%; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav_search_bar_part {
  display: flex;
  align-items: center;
}
.nav_search_bar_part .nav_search {
  display: flex;
  align-items: center;
  background-color: #f2f2f3;
  padding: 5px 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.nav_search_bar_part .nav_search input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  /* border-right: 1px solid gray; */
  padding: 3px 0px;
}
.nav_search_bar_part .nav_loaction {
  display: flex;
  align-items: center;
  background-color: #f2f2f3;
  padding: 5px 10px;
}
.nav_search_bar_part .nav_loaction input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  color: black;
  padding: 3px 0px;
}
.nav_search_bar_part button {
  border: none;
  outline: none;
  padding: 6px 12px;
  background-color: #4579f9;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

/* ------------ nav part3-------------- */
.nav_part3 {
  height: 100%;
  /* width: 30%; */
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* ------------ nav part4-------------- */
.nav_part4 {
  height: 100%;
  /* width: 30%; */
  /* background-color: green; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: -270px;
}
.nav_icons_part {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav_icons_part .icons {
  position: relative;
  display: flex;
}
.nav_icons_part .icons .iNum {
  font-size: 0.6rem;
  background-color: #4579f9;
  border-radius: 50%;
  color: white;
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -8px;
  margin-left: -6px;
}
.login_btn {
  background-color: #4579f9;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  outline: none;
}

/* ----------------- For Mobile ----------------- */
.menu_icon {
  font-size: 2rem;
  color: #4579f9;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0%;
  right: 4%;
  transition: all 0.3s ease;
}
.menuopen {
  height: auto;
  transition: all 0.3s ease;
  padding-bottom: 2vmax;
}
.logo_img {
  height: 60px;
}

/* ------------ For media Query ------------ */
@media only screen and (max-width: 1000px) {
  .logo_img {
    height: 50px;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 9vmax;
    /* height: 20vh; */
    overflow: hidden;
    padding: 0% .8vmax;
  }
  .nav_part1 {
    width: auto;
    margin-bottom: 3vmax;
  }
  .nav_part2 {
    width: auto;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: .8vmax;
  }

  .nav_search_bar_part {
    flex-direction: column;
    align-items: flex-start;
  }
  .nav_search_bar_part .nav_search {
    border-radius: 5px;
    margin-bottom: 1vmax;
  }
  .nav_search_bar_part .nav_search input {
    border: none;
  }
  .nav_search_bar_part button {
    border-radius: 5px;
    margin-top: 1vmax;
  }

  .nav_part3 {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
  }
  .login_btn {
    margin-top: 1vmax;
  }
  .menu_icon {
    display: block;
  }
  /* .nav2 {
    display: none !important;
  } */
}
