.brands_main {
  padding: 0 3vmax;
}
.Bcards {
  > .Bcard {
    height: 390px;
    overflow: hidden;
    > .Bcard_top {
      height: 60%;
      width: 100%;
      border-bottom: 2px dotted gray;
      > img {
        height: 35%;
      }
    }
    > .Bcard_bottom {
      height: 40%;
      width: 100%;
      > button {
        border: 1.5px dotted #177e0f;
        border-radius: 0%;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 20px;
        background-color: #21ae16;
        color: white;
        cursor: pointer;
        > .scissor {
          background-color: #177e0f;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
