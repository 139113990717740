.Footer_main{
    padding: 0 3vmax;
}
.app_store{
    height: 50px;
}
.play_store{
    height: 50px;
}
p{
    cursor: pointer;
}