:root {
  --swiper-navigation-size: 30px;
  --swiper-theme-color: #000;
}
* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: "dm sans" !important;
}

html,
body {
  height: 100%;
  width: 100%;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0% !important;
}

*::selection {
  background-color: #ffc050;
  color: white;
}
.topArrow {
  position: fixed;
  height: 45px;
  width: 45px;
  background-color: #ffc050;
  border-radius: 50%;
  z-index: 9999999;
  right: 4%;
  bottom: 2%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.5s;
}
.topArrow span {
  font-size: 2rem;
  margin-bottom: 10px;
  color: rgb(34, 34, 34);
}
a {
  text-decoration: none;
}
.slick-prev,
.slick-next {
  color: #000 !important;
}
.slick-prev:before,
.slick-next:before {
  color: black !important;
}
/* .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: -20px !important;
} */

.categories_sidebar {
  width: 100%;
}

.categories_links a {
  width: 100%;
  transition: all ease 0.5s;
}

.categories_sidebar > .categories_links a:hover {
  background-color: #dbdcdc;
}

.discount_col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 100%;
  gap: 15px;
}

/* =========================== no data found =========================== */
.empty-state {
  width: 750px;
  margin: 0px auto;
  background: #ffffff;
  box-shadow: 1px 2px 10px #e1e3ec;
  border-radius: 4px;
}

.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__icon img {
  width: 170px;
}

.empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}

.empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
}

.credit a {
  color: #444;
}

/* ================= when brands are inactive ================== */

.inactive::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.136);
  color: #fff;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
