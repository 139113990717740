body {
  overflow-x: hidden;
}

.coupon_part {
  >input {
    padding: 10px 10px;
    outline: none;
    width: 300px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  >button {
    padding: 9px 25px;
    outline: none;
    background-color: #fff;
    border: 0.5px solid gray;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 500;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
}

.buttons {
  display: flex;
  align-items: center;

  >button {
    padding: 10px 20px;
    border: 1px solid rgb(198, 198, 198);
    outline: none;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    background-color: white;
    transition: all 0.3s ease-in-out;

    >.icns1 {
      color: #4579f9;
    }

    >.icns2 {
      color: #ff8f8b;
    }
  }
}

.buttons button:hover {
  background-color: #dfe8ff;
}

.brand_logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  padding: 20px;
  border-radius: 50%;
  background-color: #e7e7e7;

  >img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    /* object-fit: cover; */
  }
}

@media (max-width: 506px) {
  .coupon_part {
    display: flex;
  }

  .coupon_part>input {
    width: auto;
  }

  .buttons {
    flex-direction: column;
    align-items: self-start;
    gap: 20px;
  }
}