.offers_main {
  padding: 0 3vmax;
}
.cards {
  > .card {
    height: 400px;
    overflow: hidden;
    > .card_top {
      height: 55%;
      position: relative;
      > .new_logo {
        position: absolute;
        height: 20px;
        width: 30px;
        background-color: #2ab11e;
        top: 5%;
        left: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1vmax 2vmax;
        color: white;
        clip-path: polygon(0% 0%, 100% 0, 75% 51%, 100% 100%, 0% 100%);
        padding-right: 3vmax;
      }
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
      }
      > .company_logo {
        position: absolute;
        bottom: -8%;
        display: flex;
        width: 100%;
        align-items: center;
        gap: 0.7vmax;
        padding-left: 1vmax;
        color: white;
        > .logo {
          height: 30px;
          width: 30px;
          background-color: white;
          padding: 3px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            height: 60%;
            width: 100%;
            object-fit: cover;
            background-position: center;
          }
        }
        > h6 {
          background: linear-gradient(to right, #4579f9, #04c2c9);
          padding: 5px 15px;
          padding-bottom: 7px;
          border-radius: 100px;
          border: 2px solid white;
        }
      }
    }
    > .card_bottom {
      height: 45%;
      padding-top: 1vmax;
      padding-left: 0.5vmax;
      padding-right: 0.5vmax;
      > p {
        line-height: 1.4;
        font-size: 15px;
        font-weight: 600;
      }
      > .review {
        display: flex;
        > .review_star {
          color: yellow;
        }
        > span {
          font-size: 12px;
        }
      }
      > .price {
        > h6 {
          color: #2ab11e;
          font-weight: bold;
          position: relative;
          > span {
            color: gray;
            font-size: 13px;
            margin-left: 10px;
            position: relative;
          }
        }
      }
      > .end_part {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

.offer_btn {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid gray;
}

.price > h6 > span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: gray;
  top: 50%;
  left: 0%;
}

/* swiper bullets */

.swiper-pagination-bullet {
  height: 12px !important;
  width: 12px !important;
  border-radius: 50%;
  background: rgb(214, 139, 0) !important;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: transparent;
  opacity: 0.8 !important;
  padding: 4px !important;
}
.share_btn {
  position: absolute;
  right: 3%;
}
.share_btn h6 {
  cursor: pointer;
  background: linear-gradient(to right, #c58900, #fdd200);
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
}

.titleTruncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
