.gallery_main {
  padding: 0 3vmax;
}
.gallries {
  > .gallery {
    height: 330px;
    width: 100%;
    background-color: yellow;
    position: relative;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      background-position: center;
    }
    > .Gcontent {
      position: absolute;
      top: 40%;
      right: -3%;
      color: white;
      > .btn_div {
        background: linear-gradient(to right, #447af7, #51bdc4);
        padding: 7px 16px;
      }
    }
  }
}
