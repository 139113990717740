.user_pic {
  height: 100px;
  width: 100px;

  background-size: cover;
  background-position: center;
  border-radius: 50%;
}
.mypoint_box {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 8px 0px;
  margin: 5px;
  background-color: lightgray;
}
.mypoint_box .value {
  text-align: left;
}
.mypoint_box div {
  font-size: 12px;
}
.usercolumn {
  margin-top: -630px !important;
}

@media only screen and (max-width: 600px) {
  .usercolumn {
    margin-top: 0px !important;
  }
}

.custom-btn {
  /* width: 130px;
  height: 40px; */
  color: #fff;
  border-radius: 5px;
  /* padding: 10px 25px; */
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
/* 16 */
.btn-16 {
  border: none;
  color: #000;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow: -7px -7px 20px 0px #fff9, -4px -4px 5px 0px #fff9,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}

.single-line-content {
  white-space: nowrap; /* Prevents wrapping to new lines */
  overflow: hidden; /* Hides any content that overflows the container */
}
