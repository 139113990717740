input {
  text-align: center;
  border: 1px solid #6c757d;
}
input[type="number"] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.PoolWrapper {
  border: 2px #dcd3d3 solid;
  width: 20vw;
  height: 50px;
  display: flex;
  margin-inline: auto;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
}

.plusminus {
  height: 100%;
  width: 15%;
  background: white;
  border: none;
  font-size: 30px;
  color: #5f5fce;
}

.num {
  height: 100%;
  width: 60%;
  border: none;
  outline: none;
  font-size: 30px;
}
