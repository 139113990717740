/* Globals  */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: "dm sans" !important;
}

/* Variables  */
:root {
  --color_Blue: #3f93f1;
  --color_Dark1: #1e1e1e;
  --color_Dark2: #252527;
  --color_Light1: #fff;
  --color_Light2: #fff;
}

/* =============== Sidebar =============== */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 300px;
  background: rgb(69, 121, 249);
  background: linear-gradient(
    90deg,
    rgba(69, 121, 249, 1) 49%,
    rgba(4, 194, 201, 1) 96%
  );
  transition: all 0.5s ease;
  z-index: 100;
}

.sidebar.close {
  width: 78px;
}

a {
  text-decoration: none;
}

/* --------- Logo ------------ */
.logo-box {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  color: var(--color_Light1);
  transition: all 0.5s ease;
}

.logo-box:hover {
  color: var(--color_Blue);
}

.logo-box i {
  font-size: 30px;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  transition: all 0.5s ease;
}

.sidebar.close .logo-box i {
  transform: rotate(360deg);
}

.logo-name {
  font-size: 15px;
  font-weight: 600;
}

/* ---------- Sidebar List ---------- */
.sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}

.sidebar-list::-webkit-scrollbar {
  display: none;
}

.sidebar-list li {
  transition: all 0.3s ease;
}

.sidebar-list li:hover {
  background-color: var(--color_Dark2);
}

.sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.sidebar-list li.active .title {
  background-color: var(--color_Blue);
}

.sidebar-list li.active .bxs-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.sidebar-list li .title .link {
  display: flex;
  align-items: center;
}

.sidebar-list li .title i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: var(--color_Light1);
  font-size: 20px;
}

.sidebar-list li .title .name {
  font-size: 18px;
  font-weight: 400;
  color: var(--color_Light1);
}

/* ---------------- Submenu ------------- */
.sidebar-list li .submenu {
  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: var(--color_Dark2);
}

.submenu .link {
  color: var(--color_Light2);
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
}

.submenu .link:hover {
  color: #fff;
}

.submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.sidebar.close .logo-name,
.sidebar.close .title .name,
.sidebar.close .title .bxs-chevron-down {
  display: none;
}

.sidebar.close .sidebar-list {
  overflow: visible;
}

.sidebar.close .sidebar-list li {
  position: relative;
}

.sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
}

.sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: var(--color_Dark2);
}

.sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
  color: var(--color_Light1);
}

/* =============== Home Section =============== */
.home {
  position: relative;
  background-color: var(--color_Light1);
  left: 260px;
  width: calc(100% - 260px);
  height: 100%;
  padding-bottom: 250px;
  transition: all 0.5s ease;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  /* width: fit-content; */
  cursor: pointer;
}

.home .toggle-sidebar i {
  font-size: 35px;
  color: var(--color_Dark1);
  margin-left: 15px;
}

.home .toggle-sidebar .text {
  font-size: 25px;
  color: var(--color_Dark1);
  font-weight: 600;
}

.profile {
  justify-content: right;
  height: 30px;
  width: 50px;
}

/* ============ Responsive / Breakpoints ========== */
@media (max-width: 1080px) {
}

/* For Medium Devices */
@media (max-width: 774px) {
}

@media (max-width: 560px) {
}

/* For Small Devices */
@media (max-width: 360px) {
}
