.stors_main {
  padding: 0 3vmax;
}

.Scards {
  > .Scard {
    height: 330px;
    overflow: hidden;
    > .Scard_top {
      height: 52%;
      position: relative;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-position: center;
        border-bottom-right-radius: 100px;
      }
      .Scard_logo {
        position: absolute;
        padding: 4px 10px;
        /* background: linear-gradient(
          to right,
          gray,
        rgb(187, 187, 187)
        ); */
        color: white;
        font-size: 10px;
        border-radius: 100px;
        top: 3%;
        right: 3%;
      }
      .Sstar_part {
        padding-left: 7px;
        position: absolute;
        bottom: 3%;
        display: flex;
        align-items: center;
        > span {
          background-color: orange;
          padding: 0px 5px;
          font-size: 13px;
          font-weight: bold;
        }
        >.Sstar{
          color: yellow;
        }
      }
    }
    > .Scard_bottom {
      height: 48%;
      padding-top: 1vmax;
      padding-left: 0.5vmax;
      padding-right: 0.5vmax;
      > p {
        line-height: 1.4;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 0%;
      }
      > .Send_part {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > button {
          padding: 5px 10px;
          border-radius: 5px;
          font-size: 10px;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid gray;
        }
      }
      >.Slast_part{
        display: flex;
        >.offer_btn{
          padding: 0px 12px;
          font-size: 12px;
          background-color: #3AB4BC;
          color: white;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
          border: none;
          outline: none;
        }
        >.off_btn{
          font-size: 11px;
          padding: 2px 13px;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid gray;
        }
      }
    }
  }
}
