a {
  color: #31285c;
}

.login_main {
  width: 100%;
  min-height: 77vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 20px;
}

.login_main .wrapper {
  width: 700px;
  min-height: 100px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
}

.login_main .input-field {
  width: 100%;
  height: 45px;
  border: none;
  padding: 10px;
  background-color: #eeeeee;
  color: gray;
  outline: none;
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s;
  border-radius: 5px;
}

.login_main .heading {
  color: #3b3663;
  margin-bottom: 20px;
}

.login_main .heading p {
  color: #aaa8bb;
}

.login_main .heading i {
  font-size: 30px;
  color: #4d61fc;
}

.login_main label {
  color: #aaa8bb;
  font-weight: 400;
}

.login_main button {
  width: 100%;
  height: 45px;
  border: none;
  color: #ffffff;
  background-color: #31285c;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 500;
  transition: 0.3s;
}

/* button:hover {
  background-color: #31283b;
} */

.row {
  min-width: 5px;
  min-height: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 15px;
}

.custom-checkbox {
  width: 17px;
  height: 17px;
  border-radius: 5px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-right: 5px;
}

input[type="checkbox"]:checked ~ .custom-checkbox {
  background-color: #31285c;
}

input[type="checkbox"]:checked ~ .custom-checkbox::before {
  font-family: "Font Awesome 5 Free";
  content: "\f00c";
  display: inline-block;
  font-weight: 900;
  color: #ffffff;
}

footer {
  text-align: center;
  margin: 20px;
}
