.couponTitleTruncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.points-details {
  display: none;
  position: absolute;
  background-color: lightgray;
  border: 1px solid;
  border-radius: 5%;
  min-width: 30%;
  z-index: 1000;
}

td:hover .points-details {
  display: block;
}

.data-line {
  display: flex;
  justify-content: flex-start; /* Align items to the start to avoid hiding */
  align-items: center;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  white-space: nowrap;
}

.data-item {
  margin-right: 10px; /* Only right margin to separate items */
  padding: 5px;
  background-color: #e8e8e8;
  border-radius: 3px;
  flex-shrink: 0; /* Prevents items from shrinking */
}

.data-key {
  font-weight: bold;
  margin-right: 5px;
  color: #333;
}
