.cards2 {
  position: relative;
  > .card2 {
    position: relative;
    height: 440px;
    width: auto;
    > .top {
      position: relative;
      height: 48%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      > .Clogo {
        > img {
          height: 120px;
          width: 120px;
        }
      }
      > .Cname {
        position: absolute;
        top: 1%;
        right: 0;
        background-color: #ff8b02;
        color: white;
        padding: 5px 10px;
        font-size: 0.9rem;
      }
      > .Cprice {
        height: 40px;
        width: 100%;
        background-color: #2ca9e0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0%;
      }
    }
    > .bottom {
      height: 52%;
      width: 100%;
    }
  }
}

.add_btn {
  width: 100%;
  padding: 7px 0;
  border: none;
  outline: none;
  background-color: #e3f2ff;
}

.share_btn {
  position: absolute;
  right: 3%;
}

.tri1 {
  position: absolute !important;
  top: 50% !important;
  left: 0 !important;
  transform: translateY(-50%) !important;
  height: 20px !important;
  width: 10px !important;
  background-color: #fff !important;
  clip-path: polygon(100% 50%, 0 0, 0 100%) !important;
}

.tri2 {
  position: absolute !important;
  top: 50% !important;
  right: 0 !important;
  transform: translateY(-50%) !important;
  height: 20px !important;
  width: 10px !important;
  background-color: #fff !important;
  clip-path: polygon(100% 0, 0 50%, 100% 100%) !important;
}
