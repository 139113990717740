.Fcard {
  background: linear-gradient(to bottom, #e9a229, #d29224);
  transition: all 0.2s ease-in-out;
}
.Fcard:hover{
    transform: translateY(-7px);
}
.Ficons {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
