.subnavbar_main {
  /* height: 100px; */
  width: 100%;
}

.home_icon {
  height: 35px;
  width: 35px;
  background-color: #4579f9;
}

.category_link_col {
  width: fit-content;
  background-color: blue;
}

.categories_links {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  height: 100%;
  padding: 0 10px;
}

.category_link a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  font-weight: 500;
  position: relative;
}

.category_link a::after{
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1.5px;
  background-color: #4579f9;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease-in-out;
}

.category_link a:hover::after{
  transform: scaleX(1);
}

.voucher_status{
    height: 100%;
    width: 100%;
    background-color: #4579f9;
    cursor: pointer;
   
}

.dropdown_subnav{
  position: absolute;
  right: 0;
}