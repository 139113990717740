/* Container and Overall Layout */
.Container {
  padding: 20px;
  background-color: #f5f5f5;
}

.Row {
  margin-bottom: 20px;
}

/* Header */
h2 {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Date Input and Clear Button */
input[type="date"] {
  padding: 5px 10px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Table Styles */
.Table {
  background-color: white;
  border-collapse: collapse;
}

.Table th,
.Table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.Table th {
  background-color: #f0f0f0;
  color: #333;
}

.Table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.Table-responsive {
  overflow-x: auto;
}

/* Button inside Table */
.Button {
  padding: 5px 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.Button[disabled] {
  background-color: #6c757d;
  cursor: not-allowed;
}

.Button:not([disabled]):hover {
  background-color: #218838;
}

/* Style for the form container */
.form-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for each label and input pair */
.form-container label,
.form-container input[type="date"] {
  margin: 0 10px;
}

/* Reduce margin for small screens */
@media (max-width: 600px) {
  .form-container label,
  .form-container input[type="date"] {
    margin: 0 5px;
  }
}

/* Style for the input fields */
.form-container input[type="date"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Style for the submit button */
.form-container button {
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.form-container button:hover {
  background-color: #0056b3;
}

/* Additional responsive adjustments */
@media (max-width: 600px) {
  .form-container {
    flex-direction: column;
    align-items: stretch;
  }

  .form-container label,
  .form-container input[type="date"],
  .form-container button {
    margin-bottom: 10px;
  }
}

.clear-button {
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  border-left: none; /* Removes the left border */
  border-radius: 0 4px 4px 0; /* Rounded corners on the right side */
  cursor: pointer;
}

.clear-button:hover {
  background-color: #0056b3;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey background */
  border-top: 5px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
