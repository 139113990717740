.mail_main {
  padding: 0 3vmax;
  > .mail_inner {
    background: linear-gradient(to right, #447af7, #51bdc4);
    color: white;
  }
}
.form_part {
  height: 55px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  > input {
    height: 100%;
    width: 75%;
    border: none;
    padding-left: 10px;
    background: transparent;
    outline: none;
  }
  > button {
    height: 100%;
    width: 25%;
    border-radius: 8px;
    background: #f78a1b;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
}
