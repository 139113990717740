.brands_form {
  font-weight: bold;
}
.form-check-input {
  clear: left;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem; /* JUST FOR STYLING PURPOSE */
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}
.required-asterisk {
  color: red;
}
