.hero_main {
  padding: 0 3vmax;
}
.hero_main_left,
.hero_main_right {
  height: 55vh;
}
.hero_main_left {
  position: relative;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  > .content {
    position: absolute;
    bottom: 0%;
    left: 0%;
    width: 100%;
    padding-top: 1vmax;
    padding-left: 1vmax;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.523);

    > .left {
      height: 100%;
      width: 80%;
      > .left_inner {
        height: 100%;
        width: 80%;
        > .star {
          color: yellow;
        }
        > .rates {
          color: white;
        }
      }
    }
    > .right {
      height: 100%;
      width: 20%;
      > button {
        color: white;
        border: none;
        padding: 0.5vmax 1vmax;
        border: 2px dotted #fff;
        background-color: #4579f9;
      }
    }
  }
}

.tren-img {
  mix-blend-mode: multiply;
  filter: contrast(1);
}

.hero_main_right {
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  > .top {
    padding: 10px;
    width: 100%;
    background: linear-gradient(to right, #4579f9, #04c2c9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  > .bottom {
    height: 100%;
    width: 100%;
    > .content-divs {
      height: 30%;
      width: 100%;
      border-bottom: 1px solid #d1d1d1;
      display: flex;
      > .left {
        position: relative;
        height: 100%;
        width: 30%;

        padding: 1.3vmax;
        > img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          background-position: center;
        }
      }
      > .right {
        position: relative;
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
      }
    }
  }
}

.hero_main_right::-webkit-scrollbar {
  display: none;
}

.text-style-for-anchor {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits text color from parent element */
  font-family: inherit; /* Inherits font from parent element */
  font-size: inherit; /* Inherits font size from parent element */
}

/* ------------------Media Queries------------------ */
@media (max-width: 876px) {
  .hero_main_left > .content {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1vmax;
    > .left {
      width: 100%;
    }
    > .right {
      width: 100%;
    }
  }
}
@media (max-width: 600px) {
  .hero_main_left {
    height: 35vh;
  }
}
