.featuredStor_main{
    padding: 0 3vmax;
}
.Fleft{
    background-color: #4579F9;
    padding-top: 3vmax;
    padding-bottom: 2vmax;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    >button{
        padding: 10px 30px;
        border: 2px solid white;
        background: transparent;
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        cursor: pointer;
    }
}

